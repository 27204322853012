import { type FetchContext, type FetchResponse, ofetch } from 'ofetch'
import { useToast } from 'vue-toastification'

export default defineNuxtPlugin(async ({$config}) => {
  const toast = useToast()
  const {token} = useAuthState()

  globalThis.$fetch = ofetch.create({
    // @ts-ignore
    headers: {
      'Accept': 'application/json',
      'Authorization': token.value
    },
    onResponseError(context: FetchContext & { response: FetchResponse<ResponseType> }): Promise<void> | void {
      if (context.response.status == 401) {
        window.location.replace('/logout')
      } else {
        // response ha messaggio
        if (context.response._data?.message) {
          toast.error(`${context.response._data.message}`)
        }
        // errore generico
        else {
          toast.error(`${context.response.status}: ${context.response.statusText}`)
        }
      }
    }
  })
})
