import Toast, { type PluginOptions, POSITION } from 'vue-toastification'
import '~/styles/vuetoastification.scss'

export default defineNuxtPlugin((nuxtApp) => {
  const options: PluginOptions = {
    position: POSITION.TOP_CENTER,
    timeout: 3000,
    transition: 'Vue-Toastification__fade',
    closeButton: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    hideProgressBar: true
  }
  nuxtApp.vueApp.use(Toast, options)
})
