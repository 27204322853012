import { mobile, desktop } from '~/config/colors'

export default {
  mobile: {
    dark: false,
    colors: mobile
  },
  desktop: {
    dark: false,
    colors: desktop
  }
}
