import Pusher from 'pusher-js'

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()
  const {data: user} = useAuth()

  const pusher = new Pusher(config.public.pusherAppKey, {
    cluster: config.public.pusherAppCluster
  })

  // Il nome del canale websocket al quale iscriversi
  const channelName = `${config.public.pusherChannelPrefix}.${user.value?.id}`

  console.log('Pusher subscribing', `Channel: ${channelName}`)

  const pusherChannel = pusher.subscribe(channelName)

  return {
    provide: {
      pusher,
      pusherChannel
    }
  }
})
