<template>
  <NuxtPwaManifest />
  <Head>
    <Title>{{ config.public.appName }}</Title>
    <Meta
      name='robots'
      content='noindex,follow'
    />
  </Head>
  <v-app>
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>
  </v-app>
</template>

<script
  setup
  lang="ts"
>
import { useDisplay, useTheme } from 'vuetify'
import { useOneSignal } from '@onesignal/onesignal-vue3'
import { useNotificationsStore } from '~/store/notifications'

const {$pusherChannel} = useNuxtApp()
const config = useRuntimeConfig()
const notificationsStore = useNotificationsStore()
const {status, data: user} = useAuth()
const {t} = useI18n()

/**
 * Tema
 */
const theme = useTheme()
const display = useDisplay()

onBeforeMount(() => {
  setTheme()
})
watch(display.mobile, () => {
  setTheme()
})

function setTheme() {
  theme.global.name.value = display.mobile.value ? 'mobile' : 'desktop'
}

/**
 * OneSignal
 */
const oneSignal = useOneSignal()

onMounted(async () => {
  // login ritardato per attendere inizializzazione di OneSignal
  setTimeout(async () => {
    if (status.value === 'authenticated' && !!user.value) {
      try {
        await oneSignal.login(`${user.value.id}`)
        oneSignal.User.addTag('name', user.value.full_name)
        console.log('OneSignal login', `ID: ${user.value.id}`, `Name: ${user.value.full_name}`)
      } catch (e: unknown) {
        console.log('Onesignal login ERROR', e)
      }
    }
  }, 2000)
})

/**
 * Pusher
 */
onBeforeMount(() => {
  if ($pusherChannel.subscribed) return

  $pusherChannel.bind('notifications-changed', (data: UserNotificationsChangedResponse) => {
    console.log('PUSHER notifications-changed', data)
    notificationsStore.setUnread(data.unread_notifications)
  })
})

// Ad ogni refresh della sessione, aggiorno il contatore notifiche in store
onMounted(() => {
  if (user.value?.unread_notifications !== undefined) {
    notificationsStore.setUnread(user.value?.unread_notifications)
  }
})

</script>
