import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(({vueApp, $config}) => {
  const dsn = typeof $config.public.sentryDsnPublic === 'string' ? $config.public.sentryDsnPublic : undefined

  if (dsn) {
    console.log('Setting up Sentry')
  }

  Sentry.init({
    app: vueApp,
    dsn: dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: $config.public.sentryTracesSampleRate as number,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['http://localhost:3000', $config.public.apiBaseUrl],

    // This sets the sample rate. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: $config.public.sentryReplaySampleRate as number,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: $config.public.sentryErrorReplaySampleRate as number
  })
})
