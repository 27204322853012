import { useToast } from 'vue-toastification'

export default function checkPermission(permission: string | string[]) {
  const {data: user} = useAuth()

  if (typeof permission === 'string') {
    return user.value?.permissions.includes(permission)
  } else {
    return permission.some(p => user.value?.permissions.includes(p))
  }
}

export function checkPermissionMiddleware(permission: string | string[]) {
  const {$i18n} = useNuxtApp()
  const toast = useToast()

  if (!checkPermission(permission)) {
    toast.error($i18n.t('err.no_permission'))
    return abortNavigation($i18n.t('err.no_permission'))
  }
}
