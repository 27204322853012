/*
Ad ogni avvio app, salvo nello store i resort legati all'utente
*/

import { useLodgingStore } from '~/store/lodging'

export default defineNuxtPlugin(async () => {
  const {status, data: user} = useAuth()

  const store = useLodgingStore()

  if (status.value === 'authenticated' && !!user.value) {
    store.setUserLodgings(user.value)
  }
})
