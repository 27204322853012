
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/it'
import 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import calendar from 'dayjs/plugin/calendar'


dayjs.extend(updateLocale)

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(calendar)

dayjs.tz.setDefault('Europe/Rome')

// defaultLocale: "it"


dayjs.updateLocale("it")
dayjs.locale('it')


export default dayjs
