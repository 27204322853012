import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as indexyWHPz212yYMeta } from "/workspace/pages/lodgings/[id]/index.vue?macro=true";
import { default as indexbNqlbDPszeMeta } from "/workspace/pages/lodgings/index.vue?macro=true";
import { default as index5zZyhGkDSQMeta } from "/workspace/pages/lodgings/new/index.vue?macro=true";
import { default as indextTkPG1jUYQMeta } from "/workspace/pages/login/index.vue?macro=true";
import { default as index46RszXId8PMeta } from "/workspace/pages/logout/index.vue?macro=true";
import { default as indexOBX3ADFOvXMeta } from "/workspace/pages/notifications/index.vue?macro=true";
import { default as indexINSHH3i4rpMeta } from "/workspace/pages/statistics/index.vue?macro=true";
import { default as indexZuTMMwsAT1Meta } from "/workspace/pages/tasks/[id]/index.vue?macro=true";
import { default as indexb0zMWAZxrtMeta } from "/workspace/pages/tasks/index.vue?macro=true";
import { default as indexgD7ow63TYOMeta } from "/workspace/pages/tasks/new/index.vue?macro=true";
import { default as indexeTUZgRR4uVMeta } from "/workspace/pages/tasks/repeated/[id]/index.vue?macro=true";
import { default as indexZYagRT4xzjMeta } from "/workspace/pages/tasks/repeated/new/index.vue?macro=true";
import { default as indexIHAscP6Q13Meta } from "/workspace/pages/users/[id]/index.vue?macro=true";
import { default as indexFDpy1ms2QGMeta } from "/workspace/pages/users/index.vue?macro=true";
import { default as indexl3kHCARXZ4Meta } from "/workspace/pages/users/new/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: index9S03gOyIVcMeta || {},
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: "lodgings-id",
    path: "/lodgings/:id()",
    meta: indexyWHPz212yYMeta || {},
    component: () => import("/workspace/pages/lodgings/[id]/index.vue")
  },
  {
    name: "lodgings",
    path: "/lodgings",
    meta: indexbNqlbDPszeMeta || {},
    component: () => import("/workspace/pages/lodgings/index.vue")
  },
  {
    name: "lodgings-new",
    path: "/lodgings/new",
    meta: index5zZyhGkDSQMeta || {},
    component: () => import("/workspace/pages/lodgings/new/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indextTkPG1jUYQMeta || {},
    component: () => import("/workspace/pages/login/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: index46RszXId8PMeta || {},
    component: () => import("/workspace/pages/logout/index.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/workspace/pages/notifications/index.vue")
  },
  {
    name: "statistics",
    path: "/statistics",
    meta: indexINSHH3i4rpMeta || {},
    component: () => import("/workspace/pages/statistics/index.vue")
  },
  {
    name: "tasks-id",
    path: "/tasks/:id()",
    component: () => import("/workspace/pages/tasks/[id]/index.vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/workspace/pages/tasks/index.vue")
  },
  {
    name: "tasks-new",
    path: "/tasks/new",
    component: () => import("/workspace/pages/tasks/new/index.vue")
  },
  {
    name: "tasks-repeated-id",
    path: "/tasks/repeated/:id()",
    meta: indexeTUZgRR4uVMeta || {},
    component: () => import("/workspace/pages/tasks/repeated/[id]/index.vue")
  },
  {
    name: "tasks-repeated-new",
    path: "/tasks/repeated/new",
    meta: indexZYagRT4xzjMeta || {},
    component: () => import("/workspace/pages/tasks/repeated/new/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: indexIHAscP6Q13Meta || {},
    component: () => import("/workspace/pages/users/[id]/index.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexFDpy1ms2QGMeta || {},
    component: () => import("/workspace/pages/users/index.vue")
  },
  {
    name: "users-new",
    path: "/users/new",
    meta: indexl3kHCARXZ4Meta || {},
    component: () => import("/workspace/pages/users/new/index.vue")
  }
]