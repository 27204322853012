// https://pinia.vuejs.org/core-concepts/#option-stores
import { defineStore } from 'pinia'
import checkPermission from '~/utils/check-permissions'
import { P } from '~/enums/permission'
import type { SessionData } from '#auth'

export interface State {
  userLodgings: Lodging[]
  activeId: number | undefined
}

export const useLodgingStore = defineStore('lodging', {
  persist: true,
  state: (): State => ({
    userLodgings: [],
    activeId: undefined
  }),
  actions: {
    setUserLodgings(user: SessionData) {
      this.userLodgings = user.lodgings
      // se non sono admin e se non ho lodging attivo o quello attivo non è tra quelli dell'utente, imposto il primo dei lodging dell'utente
      // (admin non ha lodging attivo)
      if (!checkPermission(P.LODGINGS_READ) && (!this.activeId || !user.lodgings.some(m => m.id === this.activeId)))
        this.setActiveId(user.lodgings[0].id)
    },

    setActiveId(activeId: number | undefined) {
      this.activeId = activeId
    }
  },
  getters: {
    active: (state) => {
      return state.userLodgings.find((m: Lodging) => m.id === state.activeId)
    }
  }
})
