import {
  Chart,
  Title,
  Legend,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
  PieController,
  ArcElement,
} from 'chart.js'

export default defineNuxtPlugin(() => {

  Chart.defaults.font.family = 'Noto Sans'

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    Tooltip,
    PointElement,
    LineElement,
    Colors,
    PieController,
    ArcElement
  )

  // configurazione legenda
  Chart.defaults.plugins.legend.position = 'bottom'
  Chart.defaults.plugins.legend.labels.boxWidth = 8
  Chart.defaults.plugins.legend.labels.boxHeight = 8
  Chart.defaults.plugins.legend.labels.padding = 16

  // configurazione tooltip
  Chart.defaults.plugins.tooltip.animation = false

})
