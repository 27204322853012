import PullToRefresh from 'pulltorefreshjs'

export default defineNuxtPlugin(nuxtApp => {
  // @ts-ignore
  const {$i18n}: { $i18n: any } = nuxtApp

  const ptr = PullToRefresh.init({
    mainElement: 'body',
    instructionsReleaseToRefresh: $i18n.t('lbl.pull_to_refresh'),
    instructionsRefreshing: $i18n.t('lbl.refreshing'),
    iconArrow: ' ',
    onRefresh() {
      window.location.reload()
    }
  })
})

