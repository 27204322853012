import OneSignalVuePlugin from '@onesignal/onesignal-vue3'

export default defineNuxtPlugin(nuxtApp => {
  // @ts-ignore
  const appId = nuxtApp.$config.public.onesignalAppId
  
  if(appId){
    nuxtApp.vueApp.use(OneSignalVuePlugin, {
      appId: appId,
      serviceWorkerPath: 'onesignal/OneSignalSDKWorker.js'
    })
  }
})
