export enum P {
  COMMENTS_READ = 'comments.read',
  COMMENTS_WRITE = 'comments.write',
  COMMENTS_WRITE_OWM = 'comments.write.own',

  LODGINGS_READ = 'lodgings.read',
  LODGINGS_READ_OWN = 'lodgings.read.own',
  LODGINGS_WRITE = 'lodgings.write',
  LODGINGS_WRITE_OWN = 'lodgings.write.own',

  LODGINGS_READ_OWN_TASKS = 'lodgings.read.own.tasks',
  LODGINGS_READ_OWN_UNASSIGNED_TASKS = 'lodgings.read.unassigned.tasks',
  LODGINGS_WRITE_OWN_TASKS = 'lodgings.write.own.tasks',
  LODGINGS_WRITE_OWN_UNASSIGNED_TASKS = 'lodgings.write.unassigned.tasks',
  LODGINGS_READ_OWN_USERS = 'lodgings.read.own.users',
  LODGINGS_WRITE_OWN_USERS = 'lodgings.write.own.users',

  PREMISES_READ = 'premises.read',
  PREMISES_READ_OWN = 'premises.read.own',
  PREMISES_WRITE = 'premises.write',
  PREMISES_WRITE_OWN = 'premises.write.own',

  STATISTICS_READ = 'statistics.read',
  STATISTICS_READ_OWN = 'statistics.read.own',

  TASKS_READ = 'tasks.read',
  TASKS_READ_OWN = 'tasks.read.own',
  TASKS_WRITE = 'tasks.write',
  TASKS_WRITE_OWN = 'tasks.write.own',

  REPEATED_TASKS_READ = 'repeated.tasks.read',
  REPEATED_TASKS_READ_OWN = 'repeated.tasks.read.own',
  REPEATED_TASKS_WRITE = 'repeated.tasks.write',
  REPEATED_TASKS_WRITE_OWN = 'repeated.tasks.write.own',

  USERS_READ = 'users.read',
  USERS_READ_OWN = 'users.read.own',
  USERS_WRITE = 'users.write'
}
