// https://pinia.vuejs.org/core-concepts/#option-stores
import { defineStore } from 'pinia'

export interface State {
  unread: number,
  notificationDrawer: boolean
}

export const useNotificationsStore = defineStore('notifications', {
  persist: true,
  state: (): State => ({
    unread: 0,
    notificationDrawer: false
  }),
  actions: {
    setUnread(unread: number) {
      this.unread = unread
    },
    setNotificationDrawer(notificationDrawer: boolean) {
      this.notificationDrawer = notificationDrawer
    }
  }
})
