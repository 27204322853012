export const mobile = {
  background: '#FEFEFE',
  surface: '#FEFEFE',
  primary: '#36577D',
  secondary: '#E2813A',
  grey: '#424242'
}

export const desktop = {
  background: '#DFE2E9',
  surface: '#FEFEFE',
  primary: '#36577D',
  secondary: '#E2813A',
  grey: '#424242'
}
